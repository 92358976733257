import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { certificates } from '../constants/certificates';
import { SlideDirection } from '../constants/slide-constants';
import { PRIMARY_FONT } from '../constants/theme';
import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//const pdfjs = require("pdfjs-dist/legacy/build/pdf.js")


interface InspectionSamplesProps {
  slideDirection?: SlideDirection,
}

type PDFFile = string | File | null;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

const InspectionSamples = ({slideDirection}: InspectionSamplesProps) => {
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(400));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down(600));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down(850));
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  const [files, setFiles] = useState<PDFFile[]>([
    'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_1.pdf',
    'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_2.pdf',
    'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_3.pdf'
  ]);
  const [pageNumbers, setPageNumbers] = useState<number[]>([1, 1, 1]);
  const [pageNumber1, setPageNumber1] = useState<number>(1);
  const [pageNumber2, setPageNumber2] = useState<number>(1);
  const [pageNumber3, setPageNumber3] = useState<number>(1);
  const [numPages1, setNumPages1] = useState<number>(1);
  const [numPages2, setNumPages2] = useState<number>(1);
  const [numPages3, setNumPages3] = useState<number>(1);
  let breakpoint = 'lg';

  if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }
  const [animateIn, setAnimateIn] = useState(true);
  const timer = setTimeout(() => {
    clearTimeout(timer);
    setAnimateIn(!animateIn);
  }, 500);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const renderPagination = (
    pageNumber: number,
    index: number
  ) => {
    let numPages = 1;
    if (index === 0) {
      numPages = numPages1;
    } else if (index === 1) {
      numPages = numPages2;
    } else if (index === 2) {
      numPages = numPages3;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="delete"
          size="large"
          disabled={pageNumber === 1}
          onClick={() => {
            const updatedPageNumbers = [...pageNumbers];
            updatedPageNumbers[index] = pageNumber - 1;
            setPageNumbers(updatedPageNumbers);
          }}
        >
          <ArrowLeftIcon fontSize="inherit" />
        </IconButton>
        <Typography>
          {`${pageNumber} / ${numPages}`}
        </Typography>
        <IconButton
          aria-label="delete"
          size="large"
          disabled={pageNumber === numPages}
          onClick={() => {
            const updatedPageNumbers = [...pageNumbers];
            updatedPageNumbers[index] = pageNumber + 1;
            setPageNumbers(updatedPageNumbers);
          }}
        >
          <ArrowRightIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  if (isMdBreakpoint) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '100px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography>
            {`Inspection Report Sample 1`}
          </Typography>
          <Document file={'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_1.pdf'} onLoadSuccess={({ numPages }: any) => {

              setNumPages1(numPages);

          }} options={options}>
            <Page width={400} pageNumber={pageNumber1} />
          </Document>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber1 === 1}
              onClick={() => {
                setPageNumber1(pageNumber1 - 1);
              }}
            >
              <ArrowLeftIcon fontSize="inherit" />
            </IconButton>
            <Typography>
              {`${pageNumber1} / ${numPages1}`}
            </Typography>
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber1 === numPages1}
              onClick={(e) => {
                e.preventDefault();
                setPageNumber1(pageNumber1 + 1);

              }}
            >
              <ArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography>
            {`Inspection Report Sample 2`}
          </Typography>
          <Document file={'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_2.pdf'} onLoadSuccess={({ numPages }: any) => {

              setNumPages2(numPages);

          }} options={options}>
            <Page width={400} pageNumber={pageNumber2} />
          </Document>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber2 === 1}
              onClick={() => {
                setPageNumber2(pageNumber2 - 1);
              }}
            >
              <ArrowLeftIcon fontSize="inherit" />
            </IconButton>
            <Typography>
              {`${pageNumber2} / ${numPages2}`}
            </Typography>
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber2 === numPages2}
              onClick={(e) => {
                e.preventDefault();
                setPageNumber2(pageNumber2 + 1);

              }}
            >
              <ArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography>
            {`Inspection Report Sample 3`}
          </Typography>
          <Document file={'https://pdfexamples.s3.amazonaws.com/Sample_Home_Inspection_Report_3.pdf'} onLoadSuccess={({ numPages }: any) => {

              setNumPages3(numPages);

          }} options={options}>
            <Page width={400} pageNumber={pageNumber3} />
          </Document>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber3 === 1}
              onClick={() => {
                setPageNumber3(pageNumber3 - 1);
              }}
            >
              <ArrowLeftIcon fontSize="inherit" />
            </IconButton>
            <Typography>
              {`${pageNumber3} / ${numPages3}`}
            </Typography>
            <IconButton
              aria-label="delete"
              size="large"
              disabled={pageNumber3 === numPages3}
              onClick={(e) => {
                e.preventDefault();
                setPageNumber3(pageNumber3 + 1);

              }}
            >
              <ArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginTop: '20px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '98%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
      <Grid container>
      {
        files.map((file, index) => {
          return (
            <Grid item
              key={index}
              sx={{
                marginRight: '5px'
              }}
            >
              <Typography>
                {`Inspection Report Sample ${index + 1}`}
              </Typography>
              <Document file={file} onLoadSuccess={({ numPages }: any) => {
                if (index === 0) {
                  setNumPages1(numPages);
                } else if (index === 1) {
                  setNumPages2(numPages);
                } else if (index === 2) {
                  setNumPages3(numPages);
                }

              }} options={options}>
                <Page pageNumber={pageNumbers[index]} />
              </Document>
              {renderPagination(pageNumbers[index], index)}
            </Grid>
          )
        })
      }
      </Grid>
      </Box>

    </Box>
  );
}

export default InspectionSamples;
