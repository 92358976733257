import React, { useEffect, useState } from 'react';
import { SlideDirection } from '../constants/slide-constants';
import { PRIMARY_FONT } from '../constants/theme';
import { Box, Grid, Slide, Typography, useMediaQuery, useTheme } from '@mui/material';

const renderBannerSection = (breakpoint: string, animateIn: boolean) => {
  let variant: ('h2' | 'h3' | 'h4' | 'h5') = 'h2';
  if (breakpoint === 'md') {
    variant = 'h3';
  } else if (breakpoint === 'sm') {
    variant = 'h4';
  } else if (breakpoint === 'xs') {
    variant = 'h5';
  }
  return null;
  // return (
  //   <div style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}>
  //     <Fade in={animateIn} timeout={{appear: 0, enter: 100, exit: 100}}>
  //       <Typography style={{ color: 'red' }} variant={variant}>
  //         <Box component='span' letterSpacing={5} fontFamily='Montserrat'>
  //           {`COVID-19 Vaccinated!`}
  //         </Box>
  //       </Typography>
  //     </Fade>
  //   </div>
  // );
}

const renderBoldText = (text: string) => {
  return (
    <Typography style={{ width: '70%' }} align='left'>
      <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
        {text}
      </Box>
    </Typography>
  );
}

const renderServiceRow = (text: string, boldText: boolean, price?: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '65%', borderBottomColor: 'red', borderBottomWidth: 2, borderWidth: 3 }}>
      {
      boldText ? 
      renderBoldText(text) : 
      <Typography style={{ width: '70%' }} align='left'>
        <Box component='span' fontFamily={PRIMARY_FONT}>
          {text}
        </Box>
      </Typography> 
      }
      {price ?
      <Typography>
        <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
          {price}
        </Box>
      </Typography> : null
      }
    </div>
  );
};

const renderSingleFamilyHomeSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Single Family Home Inspection', true, '500')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(up to 2000 Square Feet/ add $0.10 per Square Foot for larger) *', false)}
    </div>
  )
}

const renderMultiFamilyHomeSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Multi Family Inspection', true)}
      {renderServiceRow('2 Unit', false, '575')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('3 Unit', false, '650')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('4 Unit', false, '725')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(up to 2000 Square Feet/ add $0.10 per Square Foot for larger) *', false)}
    </div>
  )
}

const renderCondoSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Condominium Inspection', true, '300')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(per unit - no exterior)', false)}
    </div>
  )
}

const renderPreListingConsultation = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Consultation', true, '250')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(no report) ', false)}
    </div>
  )
}

const renderMoldAssessmentSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Mold Assessment **', true, '350')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '65%' }}>
        <Typography align='left' sx={{color: 'red'}}>
          <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
            FREE
          </Box>
        </Typography>
        <Typography style={{ marginLeft: '5px', color: 'red' }} align='left'>
          <Box component='span' fontFamily={PRIMARY_FONT}>
            - with Home Inspection
          </Box>
        </Typography>
      </div>
    </div>
  )
}

const renderMoldPostRemediationSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Post Remediation Mold Clearance **', true, '150')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
    </div>
  )
}

const renderWoodDestroyingInsectSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Wood Destroying Insects', true, '250')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '65%' }}>
        <Typography align='left' sx={{color: 'red'}}>
          <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
            FREE
          </Box>
        </Typography>
        <Typography style={{ marginLeft: '5px', color: 'red' }} align='left'>
          <Box component='span' fontFamily={PRIMARY_FONT}>
            - with Home Inspection
          </Box>
        </Typography>
      </div>
    </div>
  )
}

const renderWellFlowTestSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Well Flow Test - Gallons Per Minute (GPM)', true, '150')}
      <div style={{ display: 'flex', flexDirection: 'row', width: '65%' }}>
        <Typography align='left' sx={{color: 'red'}}>
          <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
            FREE
          </Box>
        </Typography>
        <Typography style={{ marginLeft: '5px', color: 'red' }} align='left'>
          <Box component='span' fontFamily={PRIMARY_FONT}>
            - Included with any Water Purity Test and when scheduled with a Home Inspection
          </Box>
        </Typography>
      </div>
    </div>
  )
}

const renderRadonTestSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Radon Testing', true, '150')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(when scheduled with home inspection)', false)}
    </div>
  )
}

const renderSepticDyeTestSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Septic Dye Test', true, '100')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('(when scheduled with home inspection)', false)}
    </div>
  )
}

const renderWaterPurityTestSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem', marginBottom: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('Water Purity Testing', true)}
      {renderServiceRow('Coliform/E-Coli', false, '150')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('FHA Short (lead, nitrite, nitrate, coliform)', false, '250')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
      {renderServiceRow('FHA Long (Alkalinity, Arsenic, Chloride, Total Coliform, Fluoride, Hardness, Iron, Lead, Manganese, Nitrate, Nitrite, pH, Sodium, Sulfate, Turbidity)', false, '350')}
      <div style={{ height: '1px', width: '65%', backgroundColor: 'black' }} />
    </div>
  )
}

const renderAdditionalFeedSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem' }} className="ServiceAndFees">
      {renderServiceRow('*Additional fee for homes older than 50 years and beyond 45 mile radius', false)}
      {renderServiceRow('**Additional fee applies for Mold and air sampling', false)}
    </div>
  )
}

interface ServiceAndFeesProps {
  slideDirection?: SlideDirection
}

const ServiceAndFees = ({slideDirection}: ServiceAndFeesProps) => {
  const theme = useTheme();
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(400));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isOtherLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  let breakpoint = 'lg';

  if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isOtherLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }
  const [animateIn, setAnimateIn] = useState(true);
  const timer = setTimeout(() => {
    clearTimeout(timer);
    setAnimateIn(!animateIn);
  }, 500);

  useEffect(() => {
    const element = document.getElementById('services-and-fees-root');
    if (element) {
      element.scrollTop = 0;
    }
  });

  if (isLgBreakpoint) {
    return (
      <div id='services-and-fees-root'>
        <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem', marginBottom: '2rem' }} className="ServiceAndFees">
            <Grid container>
            {renderBannerSection(breakpoint, animateIn)}
            {renderSingleFamilyHomeSection()}
            {renderMultiFamilyHomeSection()}
            {renderCondoSection()}
            {renderPreListingConsultation()}
            {renderMoldAssessmentSection()}
            {renderMoldPostRemediationSection()}
            {renderWoodDestroyingInsectSection()}
            {renderWellFlowTestSection()}
            {renderRadonTestSection()}
            {renderSepticDyeTestSection()}
            {renderWaterPurityTestSection()}
            {renderAdditionalFeedSection()}
            </Grid>
          </div>
        </Slide>
      </div>
    ); 
  }
  return (
    <div id='services-and-fees-root'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '100%', marginTop: '2rem', marginBottom: '2rem' }} className="ServiceAndFees">
        <Grid container>
          {renderBannerSection(breakpoint, animateIn)}
          {renderSingleFamilyHomeSection()}
          {renderMultiFamilyHomeSection()}
          {renderCondoSection()}
          {renderPreListingConsultation()}
          {renderMoldAssessmentSection()}
          {renderMoldPostRemediationSection()}
          {renderWoodDestroyingInsectSection()}
          {renderWellFlowTestSection()}
          {renderRadonTestSection()}
          {renderSepticDyeTestSection()}
          {renderWaterPurityTestSection()}
          {renderAdditionalFeedSection()}
        </Grid>
      </div>
    </div>
  );
}

export default ServiceAndFees;
