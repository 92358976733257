import React, { ChangeEvent, SetStateAction } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { TabValues } from '../constants/tab-constants';
import {
  Home,
  BuyBackGuarantee,
  // HonorGuarantee,
  ServiceAndFees,
  AboutMe,
} from '../views';
import { SlideDirection } from '../constants/slide-constants';
import InspectionSamples from '../views/InspectionSamples';
import { AppBar, Box, Container, Drawer, IconButton, Link, List, ListItem, ListItemText, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
    style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
            {children}
        </div>
      )}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const renderContactInfo = () => {
  return (
    <Container style={{width: '100%'}}>
      <Typography variant='h6' style={{ color: 'black' }}>
        <Box fontFamily='Open-Sans' component='span' fontWeight='bold'>
          Call us:
          <Link style={{ marginLeft: '5px' }} href='tel:518-460-1221'>
            518-460-1221
          </Link>
        </Box>
      </Typography>
      <Typography variant='body2' style={{ color: 'black' }}>
        <Box fontFamily='Open-Sans' component='span' fontWeight='bold'>
          Email:
          <Link style={{ marginLeft: '5px' }} href='mailto:dantheinspector@outlook.com' target="_top" rel="noopener noreferrer">
            dantheinspector@outlook.com
          </Link>
        </Box>
      </Typography>
    </Container>
  );
}

const {
  HOME_TAB,
  SERVICE_AND_FEES_TAB,
  BUY_BACK_GUARANTEE_TAB,
  ABOUT_ME_TAB,
  INSPECTION_SAMPLES_TAB,
  // HONOR_GUARANTEE_TAB
} = TabValues;

const menuOptions = [
  HOME_TAB,
  SERVICE_AND_FEES_TAB,
  BUY_BACK_GUARANTEE_TAB,
  ABOUT_ME_TAB,
  INSPECTION_SAMPLES_TAB
  // HONOR_GUARANTEE_TAB
];

const renderMenu = (
    setValue: React.Dispatch<React.SetStateAction<number>>,
    drawerOpen: boolean,
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
    handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void,
  ) => {
  const handleClick = (listIndex: number) => {
    setValue(listIndex);
    setDrawerOpen(false);
  };
  return (
    <div style={{ height: '100%'}}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
      <MenuIcon style={{ height: '50px', width: '50px' }}/>
      </IconButton>
      <Drawer anchor='left' open={drawerOpen} onClose={handleMenuClose}>
        <List>
          {menuOptions.map((text, index) => (
            <ListItem button key={text} onClick={() => handleClick(index)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

const renderXxsHeader = (
  tabValue: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  handleChange: ((event: ChangeEvent<{}>, value: any) => void) | undefined,
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<SetStateAction<boolean>>,
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void,
) => {
  return (
    <div style={{ width: '100%' }}>
    <AppBar style={{ backgroundColor: 'white', width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }} position="static">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '20%' }}>
          {renderMenu(setValue, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-around' }}>
              <img alt='Home Outlook Inspections logo' src='/home-outlook-logo-600x600.png' width="100px" height='100px'/>
              <img alt='Internachi Certified logo' src='/internachi-certified.png' width="80px" height='69px'/>
            </div>
            {renderContactInfo()}
          </div>
        </div>
        <div style={{ width: '20%'}}>
        </div>
      </div>
    </AppBar>
    <TabPanel value={tabValue} index={0}>
      <Home setTabValue={setValue}/>
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <ServiceAndFees />
    </TabPanel>
    <TabPanel value={tabValue} index={2}>
      <BuyBackGuarantee />
    </TabPanel>
    <TabPanel value={tabValue} index={3}>
      <AboutMe />
    </TabPanel>
    <TabPanel value={tabValue} index={4}>
      <InspectionSamples />
    </TabPanel>
    {/*<TabPanel value={tabValue} index={3}>
      <HonorGuarantee />
  </TabPanel>*/}
    </div>
  );
}

const renderXsHeader = (
  tabValue: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  handleChange: ((event: ChangeEvent<{}>, value: any) => void) | undefined,
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<SetStateAction<boolean>>,
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void,
) => {
  return (
    <div style={{ width: '100%' }}>
    <AppBar style={{ backgroundColor: 'white', width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }} position="static">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '20%' }}>
          {renderMenu(setValue, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
            <img alt='Home Outlook Inspections logo' src='/home-outlook-logo-600x600.png' width="100px" height='100px'/>
            {renderContactInfo()}
          </div>
        </div>
        <div style={{ width: '20%'}}>
          <img alt='Internachi Certified logo' src='/internachi-certified.png' width="80px" height='69px'/>
        </div>
      </div>
    </AppBar>
    <TabPanel value={tabValue} index={0}>
      <Home setTabValue={setValue}/>
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <ServiceAndFees />
    </TabPanel>
    <TabPanel value={tabValue} index={2}>
      <BuyBackGuarantee />
    </TabPanel>
    <TabPanel value={tabValue} index={3}>
      <AboutMe />
    </TabPanel>
    <TabPanel value={tabValue} index={4}>
      <InspectionSamples />
    </TabPanel>
    {/*<TabPanel value={tabValue} index={3}>
      <HonorGuarantee />
  </TabPanel>*/}
    </div>
  );
}

const renderSmallHeader = (
    tabValue: number,
    setValue: React.Dispatch<React.SetStateAction<number>>,
    handleChange: ((event: ChangeEvent<{}>, value: any) => void) | undefined,
    drawerOpen: boolean,
    setDrawerOpen: React.Dispatch<SetStateAction<boolean>>,
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
    handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void,
  ) => {
  return (
    <div style={{ width: '100%' }}>
    <AppBar style={{ backgroundColor: 'white', width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }} position="static">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '20%' }}>
          {renderMenu(setValue, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
            <img alt='Home Outlook Inspections logo' src='/home-outlook-logo-600x600.png' width="100px" height='100px'/>
            {renderContactInfo()}
          </div>
        </div>
        <div style={{ width: '20%'}}>
          <img alt='Internachi Certified logo' src='/internachi-certified.png' width="100" height='86'/>
        </div>
      </div>
    </AppBar>
    <TabPanel value={tabValue} index={0}>
      <Home setTabValue={setValue}/>
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <ServiceAndFees />
    </TabPanel>
    <TabPanel value={tabValue} index={2}>
      <BuyBackGuarantee />
    </TabPanel>
    <TabPanel value={tabValue} index={3}>
      <AboutMe />
    </TabPanel>
    <TabPanel value={tabValue} index={4}>
      <InspectionSamples />
    </TabPanel>
    {/*<TabPanel value={tabValue} index={3}>
      <HonorGuarantee />
  </TabPanel>*/}
    </div>
  );
}

const renderMediumHeader = (
  tabValue: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  handleChange: ((event: ChangeEvent<{}>, value: any) => void) | undefined,
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<SetStateAction<boolean>>,
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void,
) => {
  return (
    <div>
      <AppBar style={{ backgroundColor: 'white', width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }} position="static">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ width: '20%' }}>
            {renderMenu(setValue, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
              <img alt='Home Outlook Inspections logo' src='/home-outlook-logo-600x600.png' width="100px" height='100px'/>
              {renderContactInfo()}
            </div>
          </div>
          <div style={{ width: '20%'}}>
            <img alt='Internachi Certified logo' src='/internachi-certified.png' width="100" height='86'/>
          </div>
        </div>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <Home setTabValue={setValue}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ServiceAndFees />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <BuyBackGuarantee />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <AboutMe />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <InspectionSamples />
      </TabPanel>
      {/*<TabPanel value={tabValue} index={3}>
        <HonorGuarantee />
  </TabPanel>*/}
    </div>
  );
}

const Header = () => {
  const theme = useTheme();

  const isXxsBreakpoint = useMediaQuery(theme.breakpoints.down(450));
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(500));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down(800));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.between(800, 1139));
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up(1140));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  let breakpoint = 'lg';

  if (isXxsBreakpoint) {
    breakpoint = 'xxs';
  } else if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }

  const [tabValue, setValue] = React.useState(0);
  const [slideDirection, setSlideDirection] = React.useState(SlideDirection.left);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);

    if (newValue > tabValue) {
      setSlideDirection(SlideDirection.left);
    } else {
      setSlideDirection(SlideDirection.right);
    }
    
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDrawerOpen(true);
  };
  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setDrawerOpen(false);
  };

  if (['xxs'].includes(breakpoint)) {
    return (
      <div>
        {renderXxsHeader(tabValue, setValue, handleChange, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
      </div>
    );
  } else if (['xs'].includes(breakpoint)) {
    return (
      <div>
        {renderXsHeader(tabValue, setValue, handleChange, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
      </div>
    );
  } else if (['sm'].includes(breakpoint)) {
    return (
      <div>
        {renderSmallHeader(tabValue, setValue, handleChange, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
      </div>
    );
  } else if (['md'].includes(breakpoint)) {
    return (
      <div>
        {renderMediumHeader(tabValue, setValue, handleChange, drawerOpen, setDrawerOpen, handleMenuOpen, handleMenuClose)}
      </div>
    );
  }
  return (
    <div>
      <AppBar style={{ backgroundColor: 'white', width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }} position="static">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ width: '20%'}}>
            <img alt='Home Outlook Inspections logo' src='/home-outlook-logo-600x600.png' width="200" height='200'/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ alignItems: 'center', width: '100%', marginBottom: '1rem' }}>
              {renderContactInfo()}
            </div>
            <div>
              <Tabs indicatorColor='primary' style={{ display: 'flex' }} value={tabValue} onChange={handleChange} aria-label="Tabs">
                <Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={HOME_TAB} {...a11yProps(0)} />
                <Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={SERVICE_AND_FEES_TAB} {...a11yProps(1)} />
                <Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={BUY_BACK_GUARANTEE_TAB} {...a11yProps(2)} />
                <Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={ABOUT_ME_TAB} {...a11yProps(3)} />
                <Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={INSPECTION_SAMPLES_TAB} {...a11yProps(4)} />
                {/*<Tab style={{ color: 'black', fontFamily: 'Open-Sans' }} label={HONOR_GUARANTEE_TAB} {...a11yProps(3)} />*/}
              </Tabs>
            </div>
          </div>
          <div style={{ width: '20%'}}>
            <img alt='Internachi Certified logo' src='/internachi-certified.png' width="200" height='173'/>
          </div>
        </div>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <Home slideDirection={slideDirection} setTabValue={setValue}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ServiceAndFees slideDirection={slideDirection}/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <BuyBackGuarantee slideDirection={slideDirection}/>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <AboutMe slideDirection={slideDirection}/>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <InspectionSamples slideDirection={slideDirection}/>
      </TabPanel>
      {/*<TabPanel value={tabValue} index={3}>
        <HonorGuarantee slideDirection={slideDirection}/>
  </TabPanel>*/}
    </div>
  );
}

export default Header;