
import { Box, Typography, Avatar, Dialog, AppBar, Toolbar, IconButton, CircularProgress, Slide, Badge, styled, Accordion, AccordionSummary, AccordionDetails, Button, useTheme, Card, Divider, Tabs, Tab, Fade, Link, useMediaQuery, Grid} from '@mui/material';
import React, { useState } from 'react';
import { certificates } from '../constants/certificates';
import { SlideDirection } from '../constants/slide-constants';
import { PRIMARY_FONT } from '../constants/theme';

const renderBannerSection = (breakpoint: string, animateIn: boolean) => {
  let variant: ('h2' | 'h3' | 'h4' | 'h5') = 'h2';
  if (breakpoint === 'md') {
    variant = 'h3';
  } else if (breakpoint === 'sm') {
    variant = 'h5';
  } else if (breakpoint === 'xs') {
    variant = 'h5';
  }
  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}>
      <Fade in={animateIn} timeout={{appear: 0, enter: 100, exit: 100}}>
        <Typography style={{ color: 'red' }} variant={variant}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat'>
            Looking Out for You!
          </Box>
        </Typography>
      </Fade>
    </div>
  );
}

const renderStaticBanner = (breakpoint: string) => {
  let variant: ('h2' | 'h3' | 'h4' | 'h5' | 'h6') = 'h5';
  if (breakpoint === 'md') {
    variant = 'h4';
  } else if (breakpoint === 'sm') {
    variant = 'h6';
  } else if (breakpoint === 'xs') {
    variant = 'h6';
  }
  return (
    <Typography
      style={{marginBottom: '1.2rem', marginRight: '5px', marginLeft: '5px'}}
      variant={variant}
    >
      Expert Home Inspections in New York's Capital Region and Mohawk Valley - Trust Us to Uncover Your Home's Hidden Secrets!
    </Typography>
  )
}


const renderCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '95%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={170} width={170}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderXsCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '98%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={30} width={30}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderSmCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '98%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={30} width={30}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderMdCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '98%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={60} width={60}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '3rem' }}>
      {/*<Fade in={true} timeout={3000}>
        <Typography variant='h1' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Looking Out for You!
          </Box>
        </Typography>
      </Fade>*/}
      <img alt='looking out for you' src='/house.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
      }}/>
    </div>
  );
}

// const renderMdImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h2' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

// const renderSmImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h3' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

// const renderXsImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h4' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

const renderInformationSection = (breakpoint: string) => {
  let componentWidth = '80%';
  let componentMarginLeft = '6rem';
  let listWidth = '70%';
  if (breakpoint === 'md') {
    componentWidth = '80%';
    componentMarginLeft = '4rem';
    listWidth = '80%';
  } else if (breakpoint === 'sm') {
    componentWidth = '80%';
    componentMarginLeft = '0rem';
    listWidth = '90%';
  } else if (breakpoint === 'xs') {
    componentWidth = '80%';
    componentMarginLeft = '0rem';
    listWidth = '100%';
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: componentWidth, marginLeft: componentMarginLeft, marginBottom: '1rem' }}>
      <div>
        <Typography variant='h5' align='left'>
          <Box component='span' fontFamily={PRIMARY_FONT}>
            Your home inspection includes:
          </Box>
        </Typography>
      </div>
      <div style={{ width: listWidth, marginBottom: '1rem' }}>
        <ul>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
                Structural
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
                {' (foundation, floors, walls, columns, piers, ceilings, roof)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Exterior
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (wall claddings, flashing, trim, entry doors, garage doors and openers, windows, decks, steps, porches, railings; driveways, walkways, retaining walls, vegetation, grading and drainage with respect only to their effect on the condition of the home)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Roofing
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (roof coverings, roof drainage systems, flashings, skylights, chimneys and roof penetrations)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Plumbing
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (interior water supply and distribution system, fixtures and faucets, functional flow, interior drain, waste and vent systems, functional drainage, hot water heating systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Electrical
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (main service, main panel, subpanels, receptacles, switches, light fixtures, ceiling fans)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Heating
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (central heating systems, fireplaces, chimneys, flues, distribution systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Air Conditioning
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (central HVAC units, through the wall cooling units, distribution systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Interiors
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (walls, ceiling, floors, doors, steps, stairways, railings, countertops, built-in cabinets)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              {'Insulation & Ventilation'}
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (insulation in the attic and crawl space, vapor retarders in unfinished spaces, ventilation of attics and crawl spaces; kitchen, bathroom and laundry ventilation systems)'}
              </Box>
            </Typography>
          </li>
          <li>

              <Typography align='left'>
                <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
                Built-in Kitchen Appliances
                </Box>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                {' (range, oven, cooktops, permanently installed microwaves, range hoods, dishwasher)'}
                </Box>
              </Typography>


          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              {'Wood Destroying Insect Inspection'}
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT} style={{color: 'red'}}>
              {' ***Free with home inspection'}
              </Box>
            </Typography>
          </li>
          <li>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ marginBottom: '20px' }} align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              {'Mold Inspection/Assessment'}
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT} style={{color: 'red'}}>
              {' ***Free with home inspection'}
              </Box>
            </Typography>
            <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  {'(For a complete list of included and excluded items please refer to the '}
                  <Link href='https://dos.ny.gov/system/files/documents/2021/08/homeinspectionprofessional.pdf' target='_blank'>
                    NY Standards of Practice
                  </Link>
                  {'.)'}
                </Box>
              </Typography>
              </div>
          </li>
          
        </ul>
      </div>
    </div>
  );
}

const renderText1 = (breakpoint: string) => {
  let maxWidth = '70%';
  let textAlign: ('justify' | 'center') = 'justify';
  if (['xs', 'sm'].includes(breakpoint)) {
    maxWidth = '80%';
    textAlign = 'center';
  }

  return (
    <Typography style={{ maxWidth, textAlign, marginBottom: '2rem' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
      Licensed for Home Inspection and Mold Assessment, fully insured and certified by InterNACHI, NAMRI and the International Association of Certified Indoor Air Consultants. We
understand that buying or selling a home can be a stressful and overwhelming process. We will give you the peace of mind you need to move forward with confidence. In addition to
all of the home's structural and mechanical components, we inspect for mold and wood destroying insects at no additional cost! Every homebuyer needs someone looking out for
them and that’s what we do at Home Outlook Inspections!
      </Box>
    </Typography>
  );
}

const renderText2 = (breakpoint: string) => {
  let maxWidth = '70%';
  let textAlign: ('justify' | 'center') = 'justify';
  if (['xs', 'sm'].includes(breakpoint)) {
    maxWidth = '80%';
    textAlign = 'center';
  }
  return (
    <Typography style={{ maxWidth, textAlign, marginBottom: '2rem' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
      A home cannot pass or fail an inspection. The report is a snapshot of the home's condition at the time of the inspection.
      </Box>
    </Typography>
  );
}


const renderList = (listItems: string[]) => {
  return (listItems.map((listItem, listIndex) => {
    return (
      <Typography key={listIndex} align='left'>
        <Box component='span' fontFamily={PRIMARY_FONT}>
          {listItem}
        </Box>
      </Typography>
    );
  }));
}

const renderOfferSection = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
      <Typography style={{textDecoration: 'underline'}}>
        <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
          We offer
        </Box>
      </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          {
            renderList([
              'Home Inspection',
              'Pre-Listing Inspection',
              'Wood Destroying Insect Inspection',
              'Radon Gas Survey',
              'Well Flow Testing',
              'Water Quality Testing',
              'Mold Assessment'
            ])
          }
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography variant='h6' style={{textDecoration: 'underline'}}>
      <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
        We offer
      </Box>
    </Typography>
      <div>
        {
          renderList([
            'Home Inspection',
            'Pre-Listing Inspection',
            'Wood Destroying Insect Inspection',
            'Radon Gas Survey',
            'Well Flow Testing',
            'Water Quality Testing',
            'Mold Assessment'
          ])
        }
      </div>
    </div>
  );
}

const renderServiceAreaSection = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }} >
        <Typography style={{textDecoration: 'underline'}}>
          <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
            Service Areas
          </Box>
        </Typography>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' }}>
            {
              renderList([
                'Albany County',
                'Greene County',
                'Montogmery County',
                'Rensselaer County',
                'Saratoga County',
                'Schenectady County',
                'Schoharie County',
              ])
            }
          </div>
          <div>
          {
            renderList([
              'Delaware County',
              'Fulton County',
              'Hamilton County',
              'Herkimer County',
              'Otsego County',
              'Warren County',
              'Washington County',
            ])
          }
        </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} >
      <Typography variant='h6' style={{textDecoration: 'underline'}}>
        <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
          Service Areas
        </Box>
      </Typography>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{marginRight: '20px'}}>
          {
            renderList([
              'Albany County',
              'Greene County',
              'Montogmery County',
              'Rensselaer County',
              'Saratoga County',
              'Schenectady County',
              'Schoharie County',
            ])
          }
        </div>
        <div>
          {
            renderList([
              'Delaware County',
              'Fulton County',
              'Hamilton County',
              'Herkimer County',
              'Otsego County',
              'Warren County',
              'Washington County',
            ])
          }
        </div>
      </div>
    </div>
  );
}

const renderBuyBackGuaranteeImage = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <img alt='Honor Guarantee' width='116' height='128' src='/buy-back.png'/>
    );
  }
  return (
    <img alt='Honor Guarantee' style={{ width: '15%'}} src='/buy-back.png'/>
  );
}

const renderBuyBackGuaranteeText = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <Typography style={{ maxWidth: '90%' }}>
        <Box component='span' fontFamily={PRIMARY_FONT}>
          Your Certified Home Inspection includes a Buy Back Guarantee provided by the International Association of Certified Home Inspectors. If Home Outlook Inspections misses anything, they'll buy your home back. Guaranteed. For details, visit nachi.org/buy.
        </Box>
      </Typography>
    );
  }
  return (
    <Typography style={{ maxWidth: '60%' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
        Your Certified Home Inspection includes a Buy Back Guarantee provided by the International Association of Certified Home Inspectors. If Home Outlook Inspections misses anything, they'll buy your home back. Guaranteed. For details, visit nachi.org/buy.
      </Box>
    </Typography>
  );
}

const renderVerify = () => {
  return (
    <a href="https://www.nachi.org/verify.php?nachiid=nachi20082045" target="_blank" rel="noopener noreferrer"><img src="https://www.nachi.org/webseals/s-v2-nachi20082045.gif" width="98" height="102" alt="Certified by the International Association of Certified Home Inspectors" /></a>
  );
}

const renderResponsiveHomeView = (breakpoint: string, animateIn: boolean, setTabValue: React.Dispatch<React.SetStateAction<number>>) => {
  if (['xs'].includes(breakpoint)) {
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderStaticBanner(breakpoint)}
        {renderXsCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        {renderOfferSection(breakpoint)}
        {renderServiceAreaSection(breakpoint)}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
            <Box
              style={{cursor: 'pointer'}}
              onClick={() => {
                setTabValue(1);
              }}
            >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
            </Box>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
            <Typography>
              IAC2: 94179
            </Typography>
            <Typography>
              NAMRI: 417554
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  } else if (['sm'].includes(breakpoint)) {
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderStaticBanner(breakpoint)}
        {renderSmCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        {renderOfferSection(breakpoint)}
        {renderServiceAreaSection(breakpoint)}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
            <Typography>
              IAC2: 94179
            </Typography>
            <Typography>
              NAMRI: 417554
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  } else if (['md'].includes(breakpoint)) {
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderStaticBanner(breakpoint)}
        {renderMdCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '3rem' }}>
          {renderOfferSection(breakpoint)}
          <div style={{ marginBottom: '1rem' }}/>
          {renderServiceAreaSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
            <Typography>
              IAC2: 94179
            </Typography>
            <Typography>
              NAMRI: 417554
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  }
}

interface HomeProps {
  slideDirection?: SlideDirection,
  setTabValue: React.Dispatch<React.SetStateAction<number>>,
}

const Home = ({slideDirection, setTabValue}: HomeProps) => {
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(400));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down(600));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down(850));
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  let breakpoint = 'lg';

  if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }
  const [animateIn, setAnimateIn] = useState(true);
  const timer = setTimeout(() => {
    clearTimeout(timer);
    setAnimateIn(!animateIn);
  }, 500);

  if (['xs'].includes(breakpoint)) {
    return (
      <div style={{ height: '100%' }} className="Home">
          {renderResponsiveHomeView(breakpoint, animateIn, setTabValue)}
      </div>
    );
  } else if (['sm', 'md'].includes(breakpoint)) {
    return (
      <div style={{ height: '100%' }} className="Home">
          {renderResponsiveHomeView(breakpoint, animateIn, setTabValue)}
      </div>
    );
  }
  return (
    <div className="Home">
      <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
        <Grid container sx={{justifyContent: 'center'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {renderBannerSection(breakpoint, animateIn)}
            {renderStaticBanner(breakpoint)}
            {renderCertificatesSection()}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'center', marginBottom: '1rem' }}>
              {renderText1(breakpoint)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            {renderInformationSection(breakpoint)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'center', marginBottom: '3rem'}}>
              {renderText2(breakpoint)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'space-around', marginBottom: '3rem' }}>
              {renderOfferSection(breakpoint)}
              {renderServiceAreaSection(breakpoint)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'space-around', alignItems: 'center', marginBottom: '2rem' }}>
              {renderBuyBackGuaranteeImage(breakpoint)}
              {renderBuyBackGuaranteeText(breakpoint)}
              {renderVerify()}
            </div>
          </div>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
            <Box
              style={{cursor: 'pointer'}}
              onClick={() => {
                setTabValue(1);
                window.scrollTo(0,0); 
              }}
            >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
            <Typography>
              IAC2: 94179
            </Typography>
            <Typography>
              NAMRI: 417554
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'/cards.gif'} width={'100%'}/>
            </Box>
          </Box>
        </Grid>
      </Slide>
    </div>
  );
}

export default Home;
