import React from 'react';
import ReactPlayer from 'react-player';
import { SlideDirection } from '../constants/slide-constants';
import { PRIMARY_FONT } from '../constants/theme';
import { Box, Fade, Grid, Link, Slide, Typography, useMediaQuery, useTheme } from '@mui/material';

const renderImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '3rem', display: 'flex', justifyContent: 'center' }}>
      <Fade in={true} timeout={2000}>
        <Typography variant='h2' style={{ position: 'absolute', width: '80%', height: '100%', color: 'white', textAlign: 'center' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Buy-Back Guarantee is included.
          </Box>
        </Typography>
      </Fade>
      <img alt='looking out for you' src='/buy-back2.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%'
      }}/>
    </div>
  );
}

const renderMdImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '3rem', display: 'flex', justifyContent: 'center' }}>
      <Fade in={true} timeout={2000}>
        <Typography variant='h3' style={{ position: 'absolute', width: '80%', height: '100%', color: 'white', textAlign: 'center' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Buy-Back Guarantee is included.
          </Box>
        </Typography>
      </Fade>
      <img alt='looking out for you' src='/buy-back2.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%'
      }}/>
    </div>
  );
}

const renderSmImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '1rem', display: 'flex', justifyContent: 'center' }}>
      <Fade in={true} timeout={3000}>
        <Typography variant='h4' style={{ position: 'absolute', width: '80%', height: '100%', color: 'white', textAlign: 'center' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Buy-Back Guarantee is included.
          </Box>
        </Typography>
      </Fade>
      <img alt='looking out for you' src='/buy-back2.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%'
      }}/>
    </div>
  );
}

const renderXsImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '1rem' }}>
      <Fade in={true} timeout={2000}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Typography variant='h5' style={{ position: 'absolute', width: '80%', height: '100%', color: 'white', textAlign: 'center' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Buy-Back Guarantee is included.
          </Box>
        </Typography>
        </div>
      </Fade>
      <img alt='looking out for you' src='/buy-back2.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%'
      }}/>
    </div>
  );
}

const renderInformationSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem', marginLeft: '6rem' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              If the home inspectors miss anything,
              <Link style={{ marginLeft: '5px', marginRight: '5px' }} href='https://www.nachi.org/' target='_blank'>
                InterNACHI
              </Link>
              will buy the home back.
            </Box>
          </Typography>
        <div>
          <Typography align='left'>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              And now for the “fine print”:
            </Box>
          </Typography>
        </div>
        <div style={{ width: '70%' }}>
          <ul>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Honored for 90 days after closing.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Valid for home inspections performed for home buyers by participating InterNACHI members.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  InterNACHI will pay you whatever price you paid for the home.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Home must be listed with a licensed real estate agent.
                </Box>
              </Typography>
            </li>
            <li>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Excludes homes with material defects not present at the time of the inspection, or not required to be inspected per
                  <Link style={{marginLeft: '5px'}} href='https://www.nachi.org/sop.htm' target='_blank'>
                    InterNACHI's Residential Standards of Practice.
                  </Link>
                </Box>
              </Typography>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const renderSmInformationSection = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem', marginLeft: '1rem' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography align='left' style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              If the home inspectors miss anything,
              <Link style={{ marginLeft: '5px', marginRight: '5px' }} href='https://www.nachi.org/' target='_blank'>
                InterNACHI
              </Link>
              will buy the home back.
            </Box>
          </Typography>
        <div>
          <Typography align='left'>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              And now for the “fine print”:
            </Box>
          </Typography>
        </div>
        <div style={{ width: '90%' }}>
          <ul>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Honored for 90 days after closing.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Valid for home inspections performed for home buyers by participating InterNACHI members.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  InterNACHI will pay you whatever price you paid for the home.
                </Box>
              </Typography>
            </li>
            <li>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Home must be listed with a licensed real estate agent.
                </Box>
              </Typography>
            </li>
            <li>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  Excludes homes with material defects not present at the time of the inspection, or not required to be inspected per
                  <Link style={{marginLeft: '5px'}} href='https://www.nachi.org/sop.htm' target='_blank'>
                    InterNACHI's Residential Standards of Practice.
                  </Link>
                </Box>
              </Typography>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const renderVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer style={{ marginLeft: '6rem' }} url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '50%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

const renderMdVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer width='600px' height='338px' url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '30%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

const renderSmMdVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer width='500px' height='281px' style={{ marginBottom: '1rem' }} url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

const renderSmVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer width='500px' height='281px' style={{ marginBottom: '1rem' }} url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

const renderXsVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer width='400px' height='225px' style={{ marginBottom: '1rem' }} url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

const renderXxsVideoAndImage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '3rem' }}>
      <ReactPlayer width='300px' height='169px' style={{ marginBottom: '1rem' }} url='https://youtu.be/5pQpMt8_zx8' />
      <div style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img alt='Buy Back Guarantee' width='227' height='250' src='/buy-back.png'/>
      </div>
    </div>
  );
}

interface BuyBackGuaranteeProps {
  slideDirection?: SlideDirection
}

const BuyBackGuarantee = ({slideDirection}: BuyBackGuaranteeProps) => {
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(450));
  const isXxsImageAndVideoBreakpoint = useMediaQuery(theme.breakpoints.down(450));
  const isXsImageAndVideoBreakpoint = useMediaQuery(theme.breakpoints.down(550));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmImageAndVideoBreakpoint = useMediaQuery(theme.breakpoints.down(800));
  const isSmInformationBreakpoint = useMediaQuery(theme.breakpoints.down(720));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  let breakpoint = 'lg';

  if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }

  if (['xs'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '1rem' }} className="BuyBackGuarantee">
        <Grid container>
          {renderXsImageSection()}
          <Typography style={{ width: '100%' }} variant='h4'>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              If I miss anything, we'll buy your home back.
            </Box>
          </Typography>
          {isXxsImageAndVideoBreakpoint ? renderXxsVideoAndImage() : renderXsVideoAndImage()}
          {renderSmInformationSection()}
          <Typography variant='h5' style={{ width: '100%', marginTop: '2rem' }}>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              For more information, please visit
              <Link href='https://www.nachi.org/' target='_blank' style={{ marginLeft: '5px' }}>
                nachi.org/buy
              </Link>
            </Box>
          </Typography>
        </Grid>
      </div>
    );
  } else if (['sm'].includes(breakpoint)) {
    let videoAndImage = renderSmMdVideoAndImage;
    if (isXsImageAndVideoBreakpoint) {
      videoAndImage = renderXsVideoAndImage;
    } else if (isSmImageAndVideoBreakpoint) {
      videoAndImage = renderSmVideoAndImage;
    } else {
      videoAndImage = renderSmMdVideoAndImage;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '1rem' }} className="BuyBackGuarantee">
        <Grid container>
          {renderSmImageSection()}
          <Typography style={{ width: '100%' }} variant='h4'>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              If I miss anything, we'll buy your home back.
            </Box>
          </Typography>
          {videoAndImage()}
          {isSmInformationBreakpoint ? renderSmInformationSection() : renderInformationSection()}
          <Typography variant='h5' style={{ width: '100%', marginTop: '2rem' }}>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              For more information, please visit
              <Link href='https://www.nachi.org/' target='_blank' style={{ marginLeft: '5px' }}>
                nachi.org/buy
              </Link>
            </Box>
          </Typography>
        </Grid>
      </div>
    );
  } else if (['md'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '1rem' }} className="BuyBackGuarantee">
        <Grid container>
          {renderMdImageSection()}
          <Typography style={{ width: '100%', marginTop: '2rem' }} variant='h4'>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              If I miss anything, we'll buy your home back.
            </Box>
          </Typography>
          {renderMdVideoAndImage()}
          {renderInformationSection()}
          <Typography variant='h5' style={{ width: '100%', marginTop: '2rem' }}>
            <Box component='span' fontFamily={PRIMARY_FONT}>
              For more information, please visit
              <Link href='https://www.nachi.org/' target='_blank' style={{ marginLeft: '5px' }}>
                nachi.org/buy
              </Link>
            </Box>
          </Typography>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }} className="BuyBackGuarantee">
          <Grid container>
            {renderImageSection()}
            <Typography style={{ width: '100%', marginTop: '2rem' }} variant='h4'>
              <Box component='span' fontFamily={PRIMARY_FONT}>
                If I miss anything, we'll buy your home back.
              </Box>
            </Typography>
            {renderVideoAndImage()}
            {renderInformationSection()}
            <Typography variant='h5' style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
              <Box component='span' fontFamily={PRIMARY_FONT}>
                For more information, please visit
                <Link href='https://www.nachi.org/' target='_blank' style={{ marginLeft: '5px' }}>
                  nachi.org/buy
                </Link>
              </Box>
            </Typography>
          </Grid>
        </div>
      </Slide>
    </div>
  );
}

export default BuyBackGuarantee;
