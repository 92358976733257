import React, { useState } from 'react';
import { certificates } from '../constants/certificates';
import { SlideDirection } from '../constants/slide-constants';
import { PRIMARY_FONT } from '../constants/theme';
import { Box, Fade, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';

const renderBannerSection = (breakpoint: string, animateIn: boolean) => {
  let variant: ('h2' | 'h3' | 'h4' | 'h5') = 'h2';
  if (breakpoint === 'md') {
    variant = 'h3';
  } else if (breakpoint === 'sm') {
    variant = 'h5';
  } else if (breakpoint === 'xs') {
    variant = 'h5';
  }
  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}>
      <Fade in={animateIn} timeout={{appear: 0, enter: 100, exit: 100}}>
        <Typography style={{ color: 'red' }} variant={variant}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat'>
            Looking Out for You!
          </Box>
        </Typography>
      </Fade>
    </div>
  );
}

const renderCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '95%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={170} width={170}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderXsCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '95%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={30} width={30}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderSmCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '95%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={30} width={30}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderMdCertificatesSection = () => {
  return (
    <Box style={{
      display: 'flex',
      width: '95%',
      marginBottom: '1rem'
    }}>
    <Grid container spacing={1}>
      {
        certificates.map((certificate) => {
          return (
            <Grid item>
              <img src={certificate} height={60} width={60}/>
            </Grid>
          )
        })
      }
    </Grid>
    </Box>
  )
}

const renderImageSection = () => {
  return (
    <div style={{ position: 'relative', marginBottom: '3rem' }}>
      {/*<Fade in={true} timeout={3000}>
        <Typography variant='h1' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
          <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            Looking Out for You!
          </Box>
        </Typography>
      </Fade>*/}
      <img alt='looking out for you' src='/house.png' style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
      }}/>
    </div>
  );
}

// const renderMdImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h2' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

// const renderSmImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h3' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

// const renderXsImageSection = () => {
//   return (
//     <div style={{ position: 'relative', marginBottom: '3rem' }}>
//       {/*<Fade in={true} timeout={2000}>
//         <Typography variant='h4' style={{ position: 'absolute', width: '100%', height: '100%', color: 'white' }}>
//           <Box component='span' letterSpacing={5} fontFamily='Montserrat' fontStyle="oblique" fontWeight='bold' style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             Looking Out for You!
//           </Box>
//         </Typography>
//       </Fade>*/}
//       <img alt='looking out for you' src='./house.png' style={{
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         width: '100%'
//       }}/>
//     </div>
//   );
// }

const renderInformationSection = (breakpoint: string) => {
  let componentWidth = '80%';
  let componentMarginLeft = '6rem';
  let listWidth = '70%';
  if (breakpoint === 'md') {
    componentWidth = '80%';
    componentMarginLeft = '4rem';
    listWidth = '80%';
  } else if (breakpoint === 'sm') {
    componentWidth = '80%';
    componentMarginLeft = '0rem';
    listWidth = '90%';
  } else if (breakpoint === 'xs') {
    componentWidth = '80%';
    componentMarginLeft = '0rem';
    listWidth = '100%';
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: componentWidth, marginLeft: componentMarginLeft, marginBottom: '1rem' }}>
      <div>
        <Typography variant='h5' align='left'>
          <Box component='span' fontFamily={PRIMARY_FONT}>
            Your home inspection includes:
          </Box>
        </Typography>
      </div>
      <div style={{ width: listWidth, marginBottom: '1rem' }}>
        <ul>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
                Structural
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
                {' (foundation, floors, walls, columns, piers, ceilings, roof)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Exterior
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (wall claddings, flashing, trim, entry doors, garage doors and openers, windows, decks, steps, porches, railings; driveways, walkways, retaining walls, vegetation, grading and drainage with respect only to their effect on the condition of the home)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Roofing
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (roof coverings, roof drainage systems, flashings, skylights, chimneys and roof penetrations)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Plumbing
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (interior water supply and distribution system, fixtures and faucets, functional flow, interior drain, waste and vent systems, functional drainage, hot water heating systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Electrical
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (main service, main panel, subpanels, receptacles, switches, light fixtures, ceiling fans)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Heating
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (central heating systems, fireplaces, chimneys, flues, distribution systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Air Conditioning
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (central HVAC units, through the wall cooling units, distribution systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              Interiors
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (walls, ceiling, floors, doors, steps, stairways, railings, countertops, built-in cabinets)'}
              </Box>
            </Typography>
          </li>
          <li>
            <Typography align='left'>
              <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
              {'Insulation & Ventilation'}
              </Box>
              <Box component='span' fontFamily={PRIMARY_FONT}>
              {' (insulation in the attic and crawl space, vapor retarders in unfinished spaces, ventilation of attics and crawl spaces; kitchen, bathroom and laundry ventilation systems)'}
              </Box>
            </Typography>
          </li>
          <li>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ marginBottom: '20px' }} align='left'>
                <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
                Built-in Kitchen Appliances
                </Box>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                {' (range, oven, cooktops, permanently installed microwaves, range hoods, dishwasher)'}
                </Box>
              </Typography>
              <Typography align='left'>
                <Box component='span' fontFamily={PRIMARY_FONT}>
                  {'(For a complete list of included and excluded items please refer to the '}
                  <Link href='https://dos.ny.gov/system/files/documents/2021/08/homeinspectionprofessional.pdf' target='_blank'>
                    NY Standards of Practice
                  </Link>
                  {'.)'}
                </Box>
              </Typography>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

const renderText1 = (breakpoint: string) => {
  let maxWidth = '70%';
  let textAlign: ('justify' | 'center') = 'justify';
  if (['xs', 'sm'].includes(breakpoint)) {
    maxWidth = '80%';
    textAlign = 'center';
  }

  return (
    <Typography style={{ maxWidth, textAlign, marginBottom: '2rem' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
      Buying a home is one of life’s big decisions. A home inspection is an important step to understanding if the home is right for you. I inspect every property as if it were for me or a family member. I typically spend 3-4 hours doing complete interior and exterior evaluations. The inspection report will be delivered within 24 hours, including photos and descriptions of any deﬁciencies along with qualiﬁed repair recommendations. Every homebuyer needs someone looking out for them and that’s what we do at Home Outlook Inspections!
      </Box>
    </Typography>
  );
}

const renderText2 = (breakpoint: string) => {
  let maxWidth = '70%';
  let textAlign: ('justify' | 'center') = 'justify';
  if (['xs', 'sm'].includes(breakpoint)) {
    maxWidth = '80%';
    textAlign = 'center';
  }
  return (
    <Typography style={{ maxWidth, textAlign, marginBottom: '2rem' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
      A home cannot pass or fail an inspection. The report is a snapshot of the home's condition at the time of the inspection.
      </Box>
    </Typography>
  );
}


const renderList = (listItems: string[]) => {
  return (listItems.map((listItem, listIndex) => {
    return (
      <Typography key={listIndex} align='left'>
        <Box component='span' fontFamily={PRIMARY_FONT}>
          {listItem}
        </Box>
      </Typography>
    );
  }));
}

const renderOfferSection = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
      <Typography style={{textDecoration: 'underline'}}>
        <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
          We offer
        </Box>
      </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          {
            renderList([
              'Home Inspection',
              'Pre-Listing Inspection',
              'Wood Destroying Insect Inspection',
              'Radon Gas Survey',
              'Well Flow Testing',
              'Water Quality Testing',
            ])
          }
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography variant='h6' style={{textDecoration: 'underline'}}>
      <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
        We offer
      </Box>
    </Typography>
      <div>
        {
          renderList([
            'Home Inspection',
            'Pre-Listing Inspection',
            'Wood Destroying Insect Inspection',
            'Radon Gas Survey',
            'Well Flow Testing',
            'Water Quality Testing',
          ])
        }
      </div>
    </div>
  );
}

const renderServiceAreaSection = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }} >
      <Typography style={{textDecoration: 'underline'}}>
        <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
          Service Areas
        </Box>
      </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            renderList([
              'Albany County',
              'Greene County',
              'Montogmery County',
              'Rensselaer County',
              'Saratoga County',
              'Schenectady County',
              'Schoharie County',
            ])
          }
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} >
    <Typography variant='h6' style={{textDecoration: 'underline'}}>
      <Box component='span' fontWeight='bold' fontFamily={PRIMARY_FONT}>
        Service Areas
      </Box>
    </Typography>
      <div>
        {
          renderList([
            'Albany County',
            'Greene County',
            'Montogmery County',
            'Rensselaer County',
            'Saratoga County',
            'Schenectady County',
            'Schoharie County',
          ])
        }
      </div>
    </div>
  );
}

const renderBuyBackGuaranteeImage = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <img alt='Honor Guarantee' width='116' height='128' src='/buy-back.png'/>
    );
  }
  return (
    <img alt='Honor Guarantee' style={{ width: '15%'}} src='/buy-back.png'/>
  );
}

const renderBuyBackGuaranteeText = (breakpoint: string) => {
  if (['xs', 'sm'].includes(breakpoint)) {
    return (
      <Typography style={{ maxWidth: '90%' }}>
        <Box component='span' fontFamily={PRIMARY_FONT}>
          Your Certified Home Inspection includes a Buy Back Guarantee provided by the International Association of Certified Home Inspectors. If Home Outlook Inspections misses anything, they'll buy your home back. Guaranteed. For details, visit nachi.org/buy.
        </Box>
      </Typography>
    );
  }
  return (
    <Typography style={{ maxWidth: '60%' }}>
      <Box component='span' fontFamily={PRIMARY_FONT}>
        Your Certified Home Inspection includes a Buy Back Guarantee provided by the International Association of Certified Home Inspectors. If Home Outlook Inspections misses anything, they'll buy your home back. Guaranteed. For details, visit nachi.org/buy.
      </Box>
    </Typography>
  );
}

const renderVerify = () => {
  return (
    <a href="https://www.nachi.org/verify.php?nachiid=nachi20082045" target="_blank" rel="noopener noreferrer"><img src="https://www.nachi.org/webseals/s-v2-nachi20082045.gif" width="98" height="102" alt="Certified by the International Association of Certified Home Inspectors" /></a>
  );
}

const renderResponsiveHomeView = (breakpoint: string, animateIn: boolean, setTabValue: React.Dispatch<React.SetStateAction<number>>) => {
  if (['xs'].includes(breakpoint)) {
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderXsCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        {renderOfferSection(breakpoint)}
        {renderServiceAreaSection(breakpoint)}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
            <Box
              style={{cursor: 'pointer'}}
              onClick={() => {
                setTabValue(1);
              }}
            >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
            </Box>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  } else if (['sm'].includes(breakpoint)) {
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderSmCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        {renderOfferSection(breakpoint)}
        {renderServiceAreaSection(breakpoint)}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  } else if (['md'].includes(breakpoint)) {
    console.log("renderin MD");
    return (
      <div>
        {renderBannerSection(breakpoint, animateIn)}
        {renderMdCertificatesSection()}
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}>
        {renderText1(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderInformationSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderText2(breakpoint)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '3rem' }}>
          {renderOfferSection(breakpoint)}
          <div style={{ marginBottom: '1rem' }}/>
          {renderServiceAreaSection(breakpoint)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '1rem' }}>
          {renderBuyBackGuaranteeImage(breakpoint)}
          {renderVerify()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        {renderBuyBackGuaranteeText(breakpoint)}
        </div>
        <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px'
            }}
          >
              <Typography
                style={{
                  textDecoration: 'underline',
                  color: 'blue'
                }}
              >
                Services and Fees
              </Typography>
          </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px'}}>
            <Typography>
              NY License: 16000118419
            </Typography>
            <Typography>
              NY Mold: 01720
            </Typography>
            <Typography>
              NACHI: 20082045
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              width: '100%'
            }}
          >
            <Box>
              <img src={'cards.gif'} width={'100%'}/>
            </Box>
          </Box>
      </div>
    );
  }
}

interface AboutMeProps {
  slideDirection?: SlideDirection,
}

const AboutMe = ({slideDirection}: AboutMeProps) => {
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down(400));
  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down(600));
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down(850));
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  let breakpoint = 'lg';

  if (isXsBreakpoint) {
    breakpoint = 'xs';
  } else if (isSmBreakpoint) {
    breakpoint = 'sm';
  } else if (isMdBreakpoint) {
    breakpoint = 'md';
  } else if (isLgBreakpoint) {
    breakpoint = 'lg';
  } else if (isXlBreakpoint) {
    breakpoint = 'xl';
  }
  const [animateIn, setAnimateIn] = useState(true);
  const timer = setTimeout(() => {
    clearTimeout(timer);
    setAnimateIn(!animateIn);
  }, 500);

  let imageHeight = isXsBreakpoint ? '300px' : '500px';
  let middleMargin = isXsBreakpoint ? '10px' : '50px';
  let fontSize = isXsBreakpoint ? '10px' : '15px';

  const renderAboutMeText = () => {
    return (
      <Typography
        style={{
          fontSize
        }}
      >
        {`After working for 35 years as an Engineer, I took my passion for "all things home improvement" and a lifetime of continuous learning into my new career as a home inspector. I love to know how things work AND how they break. What’s more, I really love helping people and that's what home inspection is all about!  You'll find that I'm thorough and will take whatever time is necessary to give you the best inspection I possibly can. That’s why I only schedule 1 inspection per day - I will never rush things so I can be off to the next job.`}
      </Typography>
    )
  }




  const renderAboutMe = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px'
        }}
      >
        <Box
          style={{
            width: '40%',
            marginRight: middleMargin
          }}
        >
          {renderAboutMeText()}
        </Box>
        <Box
          style={{
            maxWidth: '50%'
          }}
        >
          <img src={'/aboutme.jpg'} width={'100%'} height={imageHeight} />
        </Box>
      </Box>
    );
  };

  return renderAboutMe();
}

export default AboutMe;
