export const certificates = [
  '/internachi-certified-professional-inspector-cpi-logo-1545171029.png',
  '/honor-guarantee-logo-1588861314.png',
  '/residential-property-inspector-logo-1546033350.png',
  '/buy-back-guarantee-logo-1545244415.png',
  '/annual-home-maintenance-inspections-logo-1550603005.png',
  '/deck-inspector-logo-1550611142.png',
  '/exterior-inspector-logo-1548192725.png',
  '/mold-inspector-logo-1546019081.png',
  '/log-home-inspector-logo-1584114694.png',
  '/new-construction-inspector-logo-1599240338.png',
  '/infrared-certified-internachi-thermography-inspector-logo-1672946651.png',
  '/internachi-covid-19-safety-guidelines-logo-1584978893.png',
  '/radon-tester-logo-1550695566.png',
  '/roof-inspector-logo-1548195546.png',
  '/moisture-intrusion-inspector-logo-1546016950.png',
  '/wdo-inspector-logo-1547057921.png',
  '/electrical-inspector-logo-1547837202.png',
  '/safe-workplace-inspector-logo-1582921363.png',
  '/chimney-inspector-logo-1545253524.png',
  '/attic-insulation-ventilation-interior-logo-1636468073.png',
  '/plumbing-inspector-logo-1546025131.png',
  '/11th-month-warranty-inspector-logo-1550602859.png',
  '/drone-pilot-logo-1545341205.png',
  '/mobile-home.png',
  '/well.png',
  '/water-quality.png',
  '/namri.png',
  '/multi-unit-housing-inspector-logo-1546024433.png',
  '/IAC2_logo_mold.png'
];