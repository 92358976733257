import React from 'react';
import { PopupWidget } from "react-calendly";
import Header from './components/Header';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  // typography: {
  //   fontFamily: [
  //     '-apple-system',
  //   ].join(','),
  // },
  palette: {
    primary: {
      main: '#0e69a1',
    },
    secondary: {
      main: '#f48830',
    },
  },
});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   toolbar: {
//     minHeight: 128,
//     alignItems: 'flex-start',
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//     alignSelf: 'flex-end',
//   },
// }));



const App = () => {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header />
        
      </ThemeProvider>
      <PopupWidget
              url="https://calendly.com/dantheinspector"
              /*
              * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
              * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
              */
              rootElement={document.getElementById("root")!}
              text="Click here to schedule!"
              textColor="#ffffff"
              color="#00a2ff"
              />
    </div>
  );
}

export default App;
